<template>
  <div>
    <van-nav-bar
      class="backBox"
      title="添加车辆"
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />

    <div class="page">
      <main>
        <div class="plate_num_box">
          <!-- <h3 style="text-align: left">请输入车牌号</h3> -->
          <div class="plate_num_box_tit" style="text-align: left">
            请输入车牌号
          </div>

          <div class="plate_num_box_input">
            <div
              class="plate_wrap"
              v-for="(item, index) in plateNumber"
              :key="index"
              @click.stop="bindFocus(index)"
            >
              <div
                class="plate_wrap_input"
                v-if="index !== 7"
                :class="[input_index === index ? ' section-dashed' : '']"
              >
                {{ item }}
              </div>
              <!-- 2 -->
              <div
                class="plate_wrap_input new_input"
                v-if="index === 7"
                :class="[input_index == index ? ' section-dashed' : '']"
              >
                <div v-if="newFlag" placeholder="">
                  {{ item }}
                </div>
                <img
                  v-if="!newFlag"
                  src="../../../assets/img/indexnewnengyuan@2x.png"
                  alt=""
                />

                <span v-if="!newFlag" class="new_vh">新能源</span>
              </div>
            </div>
          </div>
        </div>

        <div class="text-wrapper_1">
          <van-button
            :loading='btnLoading'
            loading-text="加载中..."
            class="pay_button"
            type="info"
            color="#4797EA"
            loading-type="spinner"
            @click="confirmAdd"
            >确认添加</van-button
          >
        </div>
      </main>
      <!-- 键盘 -->
      <car-keyboard
        ref="keyboard"
        :show.sync="showKeyboard"
        @getResult="setNumber"
        :plateNumber.sync="plateNumber"
        :index.sync="input_index"
        @setIndex="setIndex"
      ></car-keyboard>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import * as common from '../../../api/common'
import { Toast } from 'vant'
import { mapState } from 'vuex'
import CarKeyboard from '../../../components/CarKeyboard.vue'

export default {
  components: { CarKeyboard },
  data () {
    return {
      showKeyboard: false,
      plateNumber: ['', '', '', '', '', '', '', ''], // 键盘操作结果值
      plateNumberBak: ['', '', '', '', '', '', '', ''],
      input_index: 0,
      newFlag: false,
      index: '',
      btnLoading: false
    }
  },
  mounted () {
    document.addEventListener('click', this.handleClickOutside)
  },
  computed: {
    ...mapState({
      openid: state => state.openID.openID,
      sid: state => state.sid.sid
    })

  },
  methods: {
    async addCar (plateNum) {
      this.btnLoading = true
      Toast.loading({
        forbidClick: true,
        message: '请稍候...'
        // duration: 10000
      })
      try {
        const { data } = await axios({
          url: common.cloudPayUrl + '/pub/pay.bill.park_plate.bind_park_vh',
          method: 'POST',
          data: {
            sid: this.sid,
            openid: this.openid,
            // openid: 'o1pxwuMcjjXt2YtCXSl3UC-ZbK-w',
            plate_number: plateNum
          },
          header: {
            'content-type': 'application/json' // 默认值
          }
        })
        if (data && data.code === common.resCode.OK) {
          Toast.success('添加成功')
          this.btnLoading = false
          this.$router.push('/myCar')
        } else {
          this.$toast(data.msg || '绑定失败')
          this.btnLoading = false
        }
      } catch (e) {
        this.$toast('网络出错', e)
        this.btnLoading = false
      }
      // setTimeout(() => {

      // }, 2000)
    },
    confirmAdd () {
      const plateNum = this.plateNumber.join('')

      if (plateNum.length === 7 || plateNum.length === 8) {
        console.log('车牌', plateNum)
        this.addCar(plateNum)
      } else {
        Toast.fail('请输入正确的车牌号码')
      }
    },
    bindFocus (index) {
      if (!this.showKeyboard) {
        this.showKeyboard = true
        this.input_index = Number(index)
      } else {
        this.input_index = Number(index)
      }
    },
    handleClickOutside (event) {
      const keyboardElement = this.$refs.keyboard.$el
      if (keyboardElement && !keyboardElement.contains(event.target)) {
        if (this.showKeyboard) {
          this.showKeyboard = false
        }
      }
    },
    setNumber (data) {
      if (data[7] === '') {
        this.newFlag = false
      } else {
        this.newFlag = true
      }
    },
    setIndex (data) {
    },
    onClickLeft () {
      this.$router.go(-1)
    }

  },
  beforeDestroy () {
    document.removeEventListener('click', this.handleClickOutside)
  }
}
</script>
<style lang="scss" scoped>
main {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0.16rem;
  .plate_num_box {
    // width: 90%;
    // height: 12.5rem;
    background-color: #fff;
    box-shadow: 0px 0.01rem 0.05rem 0px rgba(68, 112, 252, 0.37);
    background-color: rgba(253, 254, 255, 1);
    border-radius: 0.08rem;
    margin-left: 0.01rem;
    display: flex;
    flex-direction: column;
    // padding: 0 0 1.0625rem;
    padding: 0.08rem;
    .plate_num_box_tit {
      font-size: 0.16rem;
      text-align: left;
      font-weight: bolder;
      margin: 0.09rem;
    }
    .plate_num_box_input {
      width: 3.24rem;
      margin-top: 0.11rem;
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      padding: 0 0.08rem 0 0.08rem;
      .plate_wrap {
        // width: 2.125rem;
        width: 0.34rem;
        height: 0.44rem;
        background-color: rgba(233, 238, 255, 1);
        display: flex;
        align-items: center;
        text-align: center;
        .section-dashed {
          height: 0.44rem;
          border: 0.01rem solid #1989fa;
        }

        .plate_wrap_input {
          height: 0.44rem;
          width: 0.34rem;
          text-align: center;
          font-size: 0.16rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .new_vh {
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 0.1rem;
            font-family: PingFangSC-Regular;
            font-weight: normal;
            text-align: left;
            white-space: nowrap;
            line-height: 0.14rem;
            margin: 0.01rem 0 0 -0.01rem;
          }
        }
        .new_input {
          background-color: rgba(233, 255, 235, 1);
          img {
            width: 0.18rem;
            height: 0.17rem;
            margin: 0 0.06rem 0 0.05rem;
          }
        }
      }
    }
  }
  .text-wrapper_1 {
    display: flex;
    width: 100%;
    .pay_button {
      width: 100%;
    }
  }
}
</style>
