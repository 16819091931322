<template>
  <div class='plate-number-keyboard-box' v-if="show">
    <div class='pnk-main' @click.stop='_preventDefault'>
      <div class='pnk-main-title'>
        <span>请点击选择车牌号：</span>
        <div class='iconfont' @click.stop='_closeKeyboard'>关闭</div>
      </div>
      <div class='pnk-content'>
        <!-- 省份面板 -->
        <div class='province-plane' v-if='showProvince'>
          <div v-for="(initem, index) in provinceList" :key="initem.id">
            <div class='line'>
              <div v-if="index === 'line4'" class='number-item handle-btn' @click.stop='_clickChangePlane'>ABC</div>
              <div class='number-item' v-for="(it, ind) in initem" :key="ind"  :data-text="it" @click.stop='_handleClick'>{{it}}</div>
              <div v-if="index === 'line4'" class='number-item handle-btn del iconfont icon-clear_keyboard' @click.stop='_handleDelete'>
                <!-- <img src="../assets/img/deleteimg@2x.png" alt=""> -->
              </div>
            </div>
          </div>
        </div>
        <!-- 字母数字面板 -->
        <div class='letter-number-plane' v-if='!showProvince'>
          <div v-for="(item, index) in letterNumberList" :key="item.id">
            <div class='line'>
              <div v-if="index === 'line4'" class='number-item handle-btn' @click.stop='_clickChangePlane'>省</div>
              <div class='number-item' v-for="(it, ind) in item" :key="ind"  :data-text="it" @click.stop='_handleClick'>{{it}}</div>
              <div v-if="index === 'line4'" class='number-item handle-btn del iconfont icon-clear_keyboard' @click.stop='_handleDelete'>
                    <!-- <img src="../assets/img/deleteimg@2x.png" alt=""> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CarKeyboard',
  props: {
    show: { // 控制键盘显示隐藏
      type: Boolean,
      value: false
    },
    showMain: {
      type: Boolean,
      value: false
    },
    plateNumber: { // 初始化的值
      type: Array,
      value: ['', '', '', '', '', '1', '2', '']
    },
    index: {
      type: Number,
      value: 0
      // observer(newVal, oldVal, changedPath) {
      //   console.log(newVal)
      //   if (newVal === 0) {
      //     this.setData({
      //       showProvince: true
      //     })
      //   } else {
      //     this.setData({
      //       showProvince: false
      //     })
      //   }
      // }
    }
  },
  data () {
    return {
      // plateNumber: ['', '', '', '', '', '', '', ''], // 键盘操作结果值
      provinceList: {
        line1: ['京', '津', '渝', '沪', '冀', '晋', '辽', '吉', '黑', '苏'],
        line2: ['浙', '皖', '闽', '赣', '鲁', '豫', '鄂', '湘', '粤', '琼'],
        line3: ['川', '贵', '云', '陕', '甘', '青', '蒙', '桂', '宁', '新'],
        line4: ['藏', '使', '领', '警', '学', '港', '澳']
      },
      letterNumberList: {
        line1: [1, 2, 3, 4, 5, 6, 7, 8, 9, 0],
        line2: ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
        line3: ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'],
        line4: ['Z', 'X', 'C', 'V', 'B', 'N', 'M']
      },
      showProvince: true, // 是否显示省份面板，控制省份面板和字符面板显示
      animationData: {} // 键盘动画
    }
  },
  methods: {
    /**
         * 键盘面板切换操作，控制省份内容和字符内容显示
         */
    _clickChangePlane: function () {
      this.showProvince = !this.showProvince
    },
    /**
         * 关闭键盘，将键盘隐藏
         */
    _closeKeyboard: function (e) {
      this.$emit('update:show', false)
      this.$emit('update:index', -1)
    },
    /**
         * 键盘主要键点击事件，将点击内容更新到plateNumber
         */
    _handleClick: function (e) {
      // console.log('键盘点击事件', e.currentTarget.dataset.text)
      // const currentResult = this.plateNumber // 当前的结果值
      const currentText = e.currentTarget.dataset.text // 当前的操作值

      // 车牌号最多8位，大多数7位，新能源8位，控制不能超过8位数
      // if (currentResult.length < 8) {
      const plateNumber = [...this.plateNumber]
      plateNumber[this.index] = currentText
      // console.log('@@', plateNumber)
      this.$emit('update:plateNumber', plateNumber)
      this.$emit('getResult', plateNumber)
      if (this.index === 0) { // 省输完后就切换到数字键盘
        this.showProvince = false
      }
      if (this.index < 7) { // 每输一个光标往下走，直到序号为7
        // this.triggerEvent('setIndex', this.data.index + 1);
        this.$emit('update:index', this.index + 1)
        this.$emit('setIndex', this.index + 1)
      }
      // this._handleResult();
      // }
    },
    /**
         * 删除回退点击事件
         */
    _handleDelete: function () {
      const plateNumber = [...this.plateNumber]
      plateNumber[this.index] = ''
      this.$emit('update:plateNumber', plateNumber)
      this.$emit('getResult', plateNumber)

      if (this.index === 1) { // 切换到省份键盘
        this.showProvince = true
      }
      if (this.index > 0) { // 每输一个光标往下走，直到序号为7
        this.$emit('update:index', this.index - 1)
        this.$emit('setIndex', this.index + 1)
      }
    },
    /**
         * 防止点击穿透
         */
    _preventDefault: function (e) { }
  },
  mounted () {
    // this.plateNumber = this.props.initValue ? this.props.initValue : ['', '', '', '', '', '', '', '']
  }
}
</script>

<style lang="scss" scoped>

  button {
    padding: 0;
  }

  .plate-number-keyboard-box {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    /*height: 2.16rem;*/
    overflow: hidden;
    background: rgba(255, 255, 255, 0);
    z-index: 9999;
  }

  .pnk-main {
    /*position: absolute;*/
    /*bottom: 0;*/
    /*left: 0;*/
    /*height: 2.16rem;*/
    width: 100%;

  }

  .pnk-main .pnk-main-title {
    text-align: left;
    font-size: .14rem;
    height: 0.3rem;
    line-height: 0.3rem;
    /* color: #333; */
    /* border-bottom: 1rpx solid #ddd; */
    padding: 0 0.05rem;
    overflow: hidden;
    background: #ddd;
  }

  .pnk-main-title text {
    float: left;
  }

  .pnk-main-title .iconfont {
    float: right;
    width: 0.35rem;
    height: 0.25rem;
    font-size: .14rem;
    text-align: center;
    color: #1989fa;
  }

  .pnk-content {
    background: #d0d5d9;
    padding: .05rem;
  }

  .line {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: .07rem;
    font-weight: bolder;
  }
 .line:not(:last-child) {

}
  // .line:last-child {
  //   margin-bottom: 0;
  // }

  .line .number-item {
    width: 0.33rem;
    height: 0.4rem;
    border-radius: .06rem;
    text-align: center;
    line-height: 0.4rem;
    background: #fff;
    font-size: 0.17rem;
  }

  .line .number-item:active {
    background: #eee;
  }

  .line .handle-btn {
    width: 0.4rem;
    background: #acb3bb;
    font-size: .14rem;
  }

  .line .iconfont {
    font-size: 0.2rem;

  }
  .del {
    background-color: #acb3bb;
    /* display: flex;
    align-items: center;
    justify-content: center; */
  }

</style>
